import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import axios from "axios";

import MainLayout from "components/Layout/MainLayout";

export interface I_HotelProduct {
  id: number;
  title: string;
  price: number;
  quantity: number;
  salePercent: number;
  hotelProductStatus: string;
  hotelProductType: string;
}

function HotelProductList() {
  const [products, setProducts] = useState<I_HotelProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    // API에서 상품 리스트 가져오기
    async function fetchProducts() {
      try {
        // const response = await axios.get("/api/hotel-products"); // API 엔드포인트
        // setProducts(response.data);
      } catch (error) {
        console.error("Failed to fetch hotel products", error);
      } finally {
        setLoading(false);
      }
    }

    fetchProducts();
  }, []);

  const handleEdit = (id: number) => {
    navigate(`/hotel-products/${id}/edit`); // 수정 페이지로 이동
  };

  if (loading) return <div>Loading...</div>;

  return (
    <MainLayout>
      <Container>
        <Title>호텔 상품 리스트</Title>
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>상품명</Th>
              <Th>가격</Th>
              <Th>수량</Th>
              <Th>할인율</Th>
              <Th>상태</Th>
              <Th>타입</Th>
              <Th>관리</Th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <Tr key={product.id}>
                <Td>{product.id}</Td>
                <Td>{product.title}</Td>
                <Td>{product.price.toLocaleString()}원</Td>
                <Td>{product.quantity}</Td>
                <Td>{product.salePercent}%</Td>
                <Td>{product.hotelProductStatus}</Td>
                <Td>{product.hotelProductType}</Td>
                <Td>
                  <EditButton onClick={() => handleEdit(product.id)}>수정</EditButton>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </MainLayout>
  );
}

export default HotelProductList;

// Styled Components
const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const EditButton = styled.button`
  background-color: ${(props) => props.theme.colors.ho1}; /* 테마 색상 사용 */
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.ho2};
  }
`;