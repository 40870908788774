import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { CATEGORY_DATA } from 'etc/CategoryData';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'assets/image/arrow-right.svg';

function Category() {
  const location = useLocation().pathname;
  const data: { [key: string]: string[] } = CATEGORY_DATA;

  const getCategoryName = (index: number) => {

    try {
      const menuLocationNameSplit = location.split('/');
      const menuLocationNameReplace = location.endsWith('/')
      ? location.replace('/', '').slice(0, -1)
      : location.replace('/', '');

      const matchingKey = Object.keys(CATEGORY_DATA).find((key) =>
        key.startsWith(menuLocationNameReplace)
      );
      const matchingValue = matchingKey ? CATEGORY_DATA[matchingKey] : null;
      const isIdParam =
        (menuLocationNameSplit[menuLocationNameSplit.length - 1] === '' || menuLocationNameSplit[menuLocationNameSplit.length - 1] === 'edit') 
        ? Number(menuLocationNameSplit[menuLocationNameSplit.length - 2]) : Number(menuLocationNameSplit[menuLocationNameSplit.length - 1]);

      if (isIdParam) {
        if (
          menuLocationNameSplit[menuLocationNameSplit.length - 1] === '' 
          || menuLocationNameSplit[menuLocationNameSplit.length - 1] === 'edit') {
          menuLocationNameSplit.pop();
        }
        menuLocationNameSplit.pop();
        menuLocationNameSplit.shift();
        menuLocationNameSplit.push('id');

        return data[menuLocationNameSplit.join('/')][index];
      } else {
        return matchingValue![index];
      }
    } catch (e) {
      return '페이지를 찾을 수 없습니다';
    }
  };

  // 도메인 체크 함수
  const isCheck = () => {
    // 'travelProduct/:id'로 시작하는 경로일 때 true 반환
    const segments = location.split('/');
    if(
      (segments[1] === 'travelProduct' &&  !isNaN(Number(segments[2])) 
      || (segments[1] === 'hotel')
      ) 
    ) {
      return false;
    } else {
      return true;
    }

  };

  return (
    <>
    {isCheck() ? (
      <S_Container>
        <S_CategoryBox>
          <S_Category>{getCategoryName(0)}</S_Category>
          <S_Category>
            <ArrowRight width="16" height="16" color="#000" />
            {getCategoryName(1)}
          </S_Category>
          {getCategoryName(2) && (
            <S_Category>
              <ArrowRight width="16" height="16" color="#000" />
              {getCategoryName(2)}
            </S_Category>
          )}
          {getCategoryName(3) && (
            <S_Category>
              <ArrowRight width="16" height="16" color="#000" />
              {getCategoryName(3)}
            </S_Category>
          )}
          {getCategoryName(4) && (
            <S_Category>
              <ArrowRight width="16" height="16" color="#000" />
              {getCategoryName(4)}
            </S_Category>
          )}
        </S_CategoryBox>
        <S_Title>
          {getCategoryName(4)
            ? getCategoryName(4)
            : getCategoryName(3)
            ? getCategoryName(3)
            : getCategoryName(2)
            ? getCategoryName(2)
            : getCategoryName(1)}
        </S_Title>
      </S_Container>
    ) : null}
  </>
  );
}

export default Category;

const S_Title = styled.h2`
  padding: 0 32px;
  font-size: 28px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
  line-height: 150%;
`;

const S_Category = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.hd1};

  &:last-child {
    font-weight: 700;
  }
`;

const S_CategoryBox = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 14px;
  padding: 16px 32px;
`;

const S_Container = styled.div``;
