import React, { useState, useEffect, useRef } from "react";

import styled from 'styled-components';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"
import koLocale from '@fullcalendar/core/locales/ko';
import { getReservationList } from 'api/hotel';
import { I_HotelReservationList, I_HotelReservationListData, I_HotelDisableList } from 'etc/types';
import { hotelReservationColor } from 'etc/constant';

import MainLayout from 'components/Layout/MainLayout';
import useLogout from 'hooks/useLogout';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';

function HotelReservation() {

  const [searchParams] = useSearchParams();

  const isGetStartDate = searchParams.get("startDate");
  const isGetEndDate = searchParams.get("endDate");
  const calendarRef = React.createRef<FullCalendar>();
  
  const location = useLocation();
  const navigate = useNavigate();
  const Logout = useLogout();

  const { hotelId } = useParams<{ hotelId : string }>();
  const [ isList, setList] = useState<I_HotelReservationList[]>([]);
  const [ isDisableList, setDisableList] = useState<I_HotelDisableList>();

  const [ isStartDate, setStartDate] = useState<string | null>(null);
  const [ isEndDate, setEndDate] = useState<string | null>(null);

  const [ calendarKey, setCalendarKey] = useState(0); // 캘린더 리렌더링을 위한 key
  const isFetching = useRef(false); 

  const [ isPopup, setPopup] = useState<{
    isVisible: boolean;
    title: string;
    content: Array<string>;
    x: number;
    y: number;
  }>({ isVisible: false, title: "", content: [], x: 0, y: 0 });

  useEffect(() => {
    if (isDisableList) {
      console.log('isDisableList: ', isDisableList)
      applyBackgroundColors();
    }
  }, [isDisableList]);

  useEffect(() => {

    if(isStartDate && isEndDate){
      navigate(`/hotel/${hotelId}/reservation?startDate=${isStartDate}&endDate=${isEndDate}`);
    }
  }, [isStartDate, isEndDate]);

  useEffect(() => {

    if(isGetStartDate && isGetEndDate){
      getAsyncList(isGetStartDate, isGetEndDate);
    }
  }, [isGetStartDate, isGetEndDate]);

  const getAsyncList = async (startDate: string, endDate: string) => {
    
    if (!hotelId) {
      console.error("!hotelId ");
      return;
    }
    
    const data = await getReservationList(
      hotelId,
      startDate,
      endDate,
    );

    if (data.ok) {
      
      const updatedList = data.res.reservation.list.map((item: I_HotelReservationList) => {
        let color = '';
        switch (item.hotelProductType) {
          case 'HOTELING':
            color = hotelReservationColor.hoteling.color;
            break;
          case 'DAYCARE':
            color = hotelReservationColor.daycare.color;
            break;
          case 'TIMECARE':
            color = hotelReservationColor.timecare.color;
            break;
          default:
            color = '#e8975e'; // 기본값 (예: 검정색)
            break;
        }
        
        const contentList = item.data.map((itemData: I_HotelReservationListData) => {
          return `<회원 id: ${itemData.userId}, 회원이름: ${itemData.userName}, 번호: ${itemData.phone}, 강아지: ${itemData.petName}, 체크인: ${itemData.checkInTime}~${itemData.checkOutTime}>`;
        });

        return {
          ...item,
          title: `${item.title} ${item.count} 회`,
          color, // color 추가
          content: contentList
        };
      });

      setList(updatedList);
      setDisableList(data.res.disable);

    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const handleDateClick = (arg: { date: Date; dateStr: string }) => {
    // console.log('Date clicked:', arg.date);
  };

  const formatDateToYMD = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth()는 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0'); // 로컬 시간 기준으로 일자를 가져옴
    return `${year}-${month}-${day}`;
  };

  // 예약이 있는날에는 배경색 지정
  const applyBackgroundColors = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;

    const allDays = document.querySelectorAll(".fc-daygrid-day");

    // 타입별 색상 매핑
    const typeToColorMap: Record<keyof I_HotelDisableList, string> = {
      HOTELING: hotelReservationColor.disableBackground.hoteling.color,
      DAYCARE: hotelReservationColor.disableBackground.daycare.color,
      TIMECARE: hotelReservationColor.disableBackground.timecare.color,
      ONEDAY: hotelReservationColor.disableBackground.oneday.color,
      ONEDAY_HOTELING: hotelReservationColor.disableBackground.onedayHoteling.color,
  };


    allDays.forEach((day) => {
      const cellDate = day.getAttribute("data-date") || ''; // null 처리

      if (!isDisableList) return;

      // 특정 날짜에 해당하는 유형 찾기
      const matchedType = Object.keys(isDisableList).find(
          (type) => isDisableList[type]?.includes(cellDate)
      );

      // 배경색 설정
      (day as HTMLElement).style.backgroundColor = matchedType
          ? typeToColorMap[matchedType]
          : '';
  });
};

  const handleDayCellDidMount = (arg: any) => {
  
    if (!isDisableList) return;
  
    // 해당 날짜를 "YYYY-MM-DD" 형식으로 변환
    const cellDate = formatDateToYMD(arg.date);
    console.log('cellDate ----------- :', cellDate)
  
    // disable 리스트에 날짜가 포함되는지 확인
    for (const [key, dates] of Object.entries(isDisableList)) {

      console.log('isDisableList key :', key)
      if (dates.includes(cellDate)) {
        // 배경색 설정
        if (key === "HOTELING") {
          arg.el.style.backgroundColor = hotelReservationColor.disableBackground.hoteling.color;
        } else if (key === "DAYCARE") {
          arg.el.style.backgroundColor = hotelReservationColor.disableBackground.daycare.color;
        } else {
          arg.el.style.backgroundColor = "#e8975e"; // 기본 색상
        }
        return; // 매칭된 날짜 처리 후 종료
      }
    }
  
    // disable 리스트에 포함되지 않는 경우 기본 색상 복원
    arg.el.style.backgroundColor = "";
  };

  const handleDatesSet = (arg: { start: Date; end: Date }) => {

    const formatDateToYMD = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth()는 0부터 시작
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const startDate = formatDateToYMD(arg.start);
    const endDate = formatDateToYMD(arg.end);

    console.log(' handleDatesSet startDate : ', startDate, ' endDate : ', endDate)
    // 기존 상태와 동일한 날짜 범위라면 업데이트하지 않음
    if (startDate !== isStartDate || endDate !== isEndDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const handleEventMouseEnter = (arg: any) => {
    const { event, jsEvent } = arg;

    setPopup({
      isVisible: true,
      title: event.title,
      content: event.extendedProps.content,
      x: jsEvent.pageX,
      y: jsEvent.pageY,
    });
  };

  const handleEventMouseLeave = () => {
    setPopup({ isVisible: false, title: "", content: [], x: 0, y: 0 });
  };


  return (
    <MainLayout>
      <PageHeader>
        호텔링 예약정보
      </PageHeader>
      <StyledCalendar>
        <FullCalendar
            // key={calendarKey}
            ref={calendarRef}
            plugins={[ dayGridPlugin, interactionPlugin ]}
            locale="ko"
            dateClick={handleDateClick}
            initialView="dayGridMonth"
            weekends={true}
            events={isList}
            dayCellDidMount={handleDayCellDidMount} // 날짜 셀 커스터마이징
            datesSet={handleDatesSet} // 달력 날짜 변경 이벤트 핸들러
            eventMouseEnter={handleEventMouseEnter} // 마우스 오버 이벤트
            eventMouseLeave={handleEventMouseLeave} // 마우스 리브 이벤트
          />
          {isPopup.isVisible && (
            <Popup style={{ top: isPopup.y, left: isPopup.x }}>
              <PopupTitle>{isPopup.title} </PopupTitle>
              <PopupContent>
                {isPopup?.content?.map((item, index) => (
                  <div key={index}>{item} </div>
                ))}
              </PopupContent>
            </Popup>
          )}
      </StyledCalendar>
    </MainLayout>
  );
}

export default HotelReservation;

const PageHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
`;

const StyledCalendar = styled.div`
  width: 100%;
  margin-top: 50px;
  .fc {
    background-color: #ffffff;
  }

  .fc-toolbar {
    background-color: #ffffff;
  }

  .fc-daygrid-day {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }

  .fc-daygrid-day:hover {
    background-color: #f5f5f5;
  }

  .fc-event {
    background-color: #007bff;
    color: #ffffff;
    border-radius: 4px;
    padding: 2px 4px;
  }

  .fc-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
  }

  .fc-button:hover {
    background-color: #0056b3;
  }

  .fc-daygrid-day-number {
    color: #333333;
    font-weight: bold;
  }
`;

const Popup = styled.div`
  position: absolute;
  background-color: #ffffff;
  color: #333;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: nowrap;
  pointer-events: none;
`;

const PopupTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const PopupContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #666;
`;
