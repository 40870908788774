import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import UserTableItem from 'components/Item/UserTableItem';
import PetstivalTableItem from 'components/Item/PetstivalTableItem';
import Dropdown from 'components/Dropdown/Dropdown';

import { I_Petstival } from 'etc/types';
import { getUserList, getUserNicknameSearch } from 'api/user';
import { getPetstivalList, getPetstivalDetail } from 'api/petstival';
import useLogout from 'hooks/useLogout';

function HotelList() {

  const Logout = useLogout();

  return (
    <MainLayout>
      <PageHeader>
        호텔링 지점 정보
      </PageHeader>
    </MainLayout>
  );
}

export default HotelList;

const PageHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
`;