import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import MainLayout from "components/Layout/MainLayout";
// import axios from "axios";

export interface I_HotelProduct {
  id: number;
  title: string;
  content?: string;
  description?: string;
  originPrice?: number;
  price: number;
  extraPrice?: number;
  hourlyPrice?: number;
  salePercent?: number;
  quantity: number;
  validityPeriod?: number;
  expireRule?: string;
  hotelProductStatus: string;
  hotelProductType: string;
}

const HotelProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<I_HotelProduct>({
    mode: "onChange",
    defaultValues: {}, // 초기 값은 API 응답 후 설정
  });

  // 데이터 초기화
  useEffect(() => {
    async function fetchProduct() {
      try {
        // const response = await axios.get(`/api/hotel-products/${id}`);
        // reset(response.data); // API 데이터로 폼 초기화
      } catch (error) {
        console.error("Failed to fetch product data", error);
        alert("데이터를 가져오는 데 실패했습니다.");
        navigate("/hotel-products"); // 에러 발생 시 목록 페이지로 이동
      }
    }

    fetchProduct();
  }, [id, reset, navigate]);

  // 폼 제출
  const onSubmit = async (data: I_HotelProduct) => {
    try {
      // await axios.put(`/api/hotel-products/${id}`, data);
      alert("수정되었습니다.");
      navigate("/hotel-products");
    } catch (error) {
      console.error("Failed to update product data", error);
      alert("수정에 실패했습니다.");
    }
  };

  return (
    <MainLayout>
      <Container>
        <Title>호텔 상품 상세보기</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>상품명</Label>
            <Input
              {...register("title", { required: "상품명은 필수 입력값입니다." })}
            />
            {errors.title && <ErrorText>{errors.title.message}</ErrorText>}
          </FormGroup>

          <FormGroup>
            <Label>설명</Label>
            <TextArea {...register("description")} />
          </FormGroup>

          <FormGroup>
            <Label>원가</Label>
            <Input type="number" {...register("originPrice")} />
          </FormGroup>

          <FormGroup>
            <Label>가격</Label>
            <Input
              type="number"
              {...register("price", { required: "가격은 필수 입력값입니다." })}
            />
            {errors.price && <ErrorText>{errors.price.message}</ErrorText>}
          </FormGroup>

          <FormGroup>
            <Label>할인율</Label>
            <Input type="number" {...register("salePercent")} />
          </FormGroup>

          <FormGroup>
            <Label>수량</Label>
            <Input
              type="number"
              {...register("quantity", { required: "수량은 필수 입력값입니다." })}
            />
            {errors.quantity && <ErrorText>{errors.quantity.message}</ErrorText>}
          </FormGroup>

          <FormGroup>
            <Label>유효기간</Label>
            <Input type="number" {...register("validityPeriod")} />
          </FormGroup>

          <FormGroup>
            <Label>상품 상태</Label>
            <Select {...register("hotelProductStatus")}>
              <option value="ACTIVE">활성화</option>
              <option value="INACTIVE">비활성화</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>상품 타입</Label>
            <Select {...register("hotelProductType")}>
              <option value="HOTELING">호텔링</option>
              <option value="DAYCARE">유치원</option>
              <option value="TIMECARE">시간권</option>
            </Select>
          </FormGroup>

          <ButtonGroup>
            <SubmitButton type="submit">저장</SubmitButton>
            <CancelButton type="button" onClick={() => navigate("/hotel-products")}>
              취소
            </CancelButton>
          </ButtonGroup>
        </Form>
      </Container>
    </MainLayout>
  );
};

export default HotelProductDetail;

// Styled Components
const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => props.theme.colors.ho1};
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.ho2};
  }
`;

const CancelButton = styled.button`
  background-color: #ddd;
  color: #000;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #bbb;
  }
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.ho6};
  font-size: 12px;
`;
