import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import styled from 'styled-components';

import { ReactComponent as Delete } from 'assets/image/minus-circle.svg';

import MainLayout from 'components/Layout/MainLayout';
import MiniButton from 'components/Button/MiniButton';
import LongButton from 'components/Button/LongButton';
import RegisterInput from 'components/Input/RegisterInput';
import CategoryDropdown from 'components/Dropdown/CategoryDropdown';

import { HorizontalSortableComponent  } from 'components/Sortable/HorizontalSortable';
import { ReactDnd  } from 'components/ReactBeautifulDnd/ReactBeautifulDndOld';
import DraggableList from 'components/ReactBeautifulDnd/ReactBeautifulDnd';


import { I_TravelProduct, I_TravelProductCategory, I_TravelProductRegistrationForm, I_TravelProductRecommend, I_TravelProductOptionDate, I_TravelProductDisableDate } from 'etc/types';
import { EEnableStatus, EActiveStatus, ERecommendStatus, EBooleanStatus } from 'etc/enum';
import useLogout from 'hooks/useLogout';
import Spinner from 'components/Spinner/Spinner';
import { travelproductRecommend, travelproductActiveStatus, travelproductEnableStatus } from 'etc/json';

import { getTravelProductList, getTravelProductDetail, postCreateTravelProduct, getTravelProductCategoryList,
  patchTravelProduct
} from 'api/travelProduct';

import { getBrandList, getCategoryList, postProductAdd } from 'api/store';
import { postImageUpload, postImagesUpload } from 'api/images';

import ToastEditor from 'components/ToastUiEditor/Editor';

import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BasicMap from 'components/KakaoMap/BasicMap'
import SearchMapWithClickAndMarker from 'components/KakaoMap/SearchMapWithClickAndMarker'
import { clearConfigCache } from 'prettier';
import { defaultRefundRule } from 'etc/variable';



// import '@toast-ui/editor/dist/toastui-editor.css';
// import { Editor } from '@toast-ui/react-editor';

// import DynamicEditor from '../../../components/ToastUiEditor/DynamicEditor'


function TravelProductRegistration() {
  const navigate = useNavigate();
  const Logout = useLogout();
  const today = new Date(); // 오늘
  today.setHours(0, 0, 0, 0);
  const format = "YYYY-MM-DD";
  const { travelProductId } = useParams<{ travelProductId: string }>();

  const [ isCategoryList, setCategoryList ] = useState<I_TravelProductCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFiles, setFiles] = useState<File[]>([]);
  
  const [isTravelProduct, setTravelProduct] = useState<I_TravelProduct>();

  const [ isDateList, setDateList ] = useState<DateObject[]>([]);
  // new DateObject().set({ day: 4, format }),
  const { 
    register, 
    watch, 
    getValues, 
    setValue, 
    handleSubmit, 
    formState: { errors }, 
    control, 
  } = useForm<I_TravelProductRegistrationForm>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      title: '',
      content: '',
      price: 0,
      salePercent: 0,
      city: '',
      place: '',
      refundRule: defaultRefundRule,
      refundDate: 10,
      travelProductStatus: '',
      travelProductCategoryId: 0,
      recommend: false,
      latt: 37.51441, // 노량진 드림스퀘어 위도
      long: 126.9395, // 노량진 드림스퀘어 경도
      address: '',
      travelProductDisableDate: [],
      travelProductOptions: [],
      uploadFiles: [],
    },
  });

  const {
    fields: optionFields,
    append: optionAppend,
    remove: optionRemove,
  } = useFieldArray<I_TravelProductRegistrationForm>({
    control,
    name: 'travelProductOptions',
  });


  // 제출
  const onSubmit = async (data: I_TravelProductRegistrationForm) => {
    if (String(data.recommend) === "true" ) {
      data.recommend = true;
    } else {
      data.recommend = false; // Handle boolean conversion or any other truthy value
    }

    // setIsLoading(true);
    data.uploadFiles = isFiles;

    const formData = new FormData();

    // 파일 배열을 FormData에 추가
    if (data.uploadFiles && data.uploadFiles.length > 0) {
      formData.append('path', 'travelproduct');
      // formData.append('files', isFiles); // 다중 파일 처리
      data.uploadFiles.forEach((file) => {
        formData.append('uploadFiles', file); // 다중 파일 처리
      });
    }

     // 배열 형태의 travelProductDisableDate 처리
    if (data.travelProductDisableDate && Array.isArray(data.travelProductDisableDate)) {
      // 방법 1: 배열의 각 항목을 개별적으로 추가
      // data.travelProductDisableDate.forEach((item, index) => {
      //   formData.append(`travelProductDisableDate[${index}].date`, item.date); // 배열의 각 항목을 개별적으로 추가
      // });
      
      // 또는 방법 2: 배열을 JSON으로 직렬화하여 추가
      formData.append('travelProductDisableDate', JSON.stringify(data.travelProductDisableDate));
    }

    // 다른 데이터 추가
    Object.keys(data).forEach((key) => {
      if (key !== "uploadFiles" && key !== "travelProductDisableDate" && key !== "travelProductOptions"  ) {
        formData.append(key, (data as any)[key]); // 파일을 제외한 다른 데이터 추가
      }
    });

    Array.from(formData.entries()).forEach(([key, value]) => {
      // console.log(`------------ ${key}:`, value);
    });

    console.log('onSubmit data : ', data);
    const result = await patchTravelProduct(Number(travelProductId), formData);
    console.log('result : ', result)
    // return;
    if (result.ok) {
      alert('상품이 수정 되었습니다. 생성한 상품 상세페이지로 이동합니다.');
      // navigate(`/travelProduct/list`);
    } else {
      alert(`${result.res.error.code}:${result.res.error.message}` );
    }
    return;

  };

  
  // 카테고리 리스트
  const getAsyncCategoryList = async () => {
    const data = await getTravelProductCategoryList();
    if (data.ok) {
      console.log('data : ', data)
      setCategoryList(data.res.list);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };


  // 가격 리스트 불러오기
  const getTravelProductApi = async () => {
    try {
      const response = await getTravelProductDetail(Number(travelProductId));
      console.log('getTravelProductApi response : ', response)
      if (response.ok) {

        const travelProduct = response.res;
        setTravelProduct(travelProduct);

        // API로 받아온 데이터를 폼 필드에 설정
        setValue('title', travelProduct.title || '');
        setValue('content', travelProduct.content || '');
        setValue('price', travelProduct.price || 0);
        setValue('salePercent', travelProduct.salePercent || 0);
        setValue('city', travelProduct.city || '');
        setValue('place', travelProduct.place || '');
        setValue('refundRule', travelProduct.refundRule || '');
        setValue('refundDate', travelProduct.refundDate || 10);
        setValue('travelProductStatus', travelProduct.travelProductStatus || '');
        setValue('travelProductCategoryId', travelProduct.travelProductCategoryId || 0);
        setValue('recommend', travelProduct.recommend || false);
        setValue('latt', travelProduct.latt || 37.51441);
        setValue('long', travelProduct.long || 126.9395);
        setValue('address', travelProduct.address || '');

        // 
        /**
         * 
         * travelProductDisableDate 형태 변환. [] => {}
         * ["2024-10-27", "2024-10-28", "2024-10-29"] 형태를
         * [{"date": "2024-10-27"}], [{"date": "2024-10-28"}], [{"date": "2024-10-29"}] 로 변경
         */
        const disableDates = travelProduct.disableDateList.map(
          (date: string) => ({ date })
        );
        
        console.log('disableDates ;' , disableDates)
        setValue('travelProductDisableDate', disableDates); // DateObject 형태로 변환하여 설정
  
        
        // setValue('travelProductDisableDate', travelProduct.travelProductDisableDate || []);

      } else {
        console.error('정보 조회 실패');
      }
    } catch (error) {
      console.error('정보 조회 에러:', error);
    } finally {
      setIsLoading(false);
    }
  };

  
  const handlerDescription = (description: string) => {
    setValue('content', description);
  };
  
  const handleItemsChangeDnd = (newItems: any) => {
    const reorderedFiles = newItems.map((item: any) => item.file);
    console.log('handleItemsChangeDnd : ',reorderedFiles )
    setFiles(reorderedFiles);
  };
  
  const uploadFiles = watch('uploadFiles');
  const uploadFilesArray = Array.from(uploadFiles);
  const transformedImages = uploadFilesArray.map((file: File, index: number) => ({
    id: index.toString(),
    fileName: file.name,
    file: file,
  }));

  const setLattLong = (latt: number, long: number) => {
    console.log('parents latt : ', latt, 'long : ', long)
    setValue("latt", latt); // 위도 설정
    setValue("long", long); // 경도 설정
  };

  const handleFileSet = (event: React.ChangeEvent<HTMLInputElement>) => {

    console.log('1 handleFileSet : ', event)
    const files = Array.from(event.target.files || []) as File[];
    setFiles(files);
  };

  useEffect(() => {
    getAsyncCategoryList();
  }, []);
  useEffect(() => {
    if(travelProductId){
      getTravelProductApi();
    }
  }, [travelProductId]);

  
  useEffect(() => {
    if(isFiles){
      console.log('isFiles : ', isFiles)
      setValue("uploadFiles", isFiles); // 경도 설정
    }
  }, [isFiles]);

  useEffect(() => {

    if(watch('travelProductDisableDate')){
      console.log('travelProductDisableDate 222222 : ', watch('travelProductDisableDate'))
    }
    // const currentDisableDates = watch('travelProductDisableDate');
    // if (currentDisableDates) {
    //   setDisableDates(currentDisableDates); // 값을 감지하고 상태 업데이트
  }, [watch('travelProductDisableDate')]); // watch 대상이 변경될 때마다 useEffect 실행
  
  
  return (
    <MainLayout>

      <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} onSubmit={handleSubmit(onSubmit)}>
        <S_TopContainer>
          <S_BasicInfoTitle>기본 정보</S_BasicInfoTitle>
          <S_BasicInfoContainer>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>여행 상품명</S_Label>
           
                <RegisterInput
                  errorMessage={errors?.title?.message} // 오류 메시지 전달
                  isError={errors?.title ? true : false}
                  register={register('title', 
                  { required: '내용을 입력해주세요' }
                  )}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              
              <S_BasicInfoWrapper>
                <S_Label>카테고리</S_Label>
                <S_Select {...register('travelProductCategoryId', 
                  { required: '내용을 입력해주세요', valueAsNumber: true }
                )} 
                defaultValue={""} >
                  <option value={""} >선택해주세요</option> {/* 기본값을 설정하는 옵션 */}
                  {isCategoryList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </S_Select>
                {errors.travelProductCategoryId && <S_ErrorMessage>{errors.travelProductCategoryId.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>

            </S_BasicInfoRowWrapper>
            
            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>
              <S_BasicInfoWrapper>
                <S_Label style={{ color: '' }}>도시</S_Label>
                <RegisterInput
                  errorMessage={errors?.city?.message} // 오류 메시지 전달
                  isError={errors?.city ? true : false}
                  register={register('city', 
                  {required: '내용을 입력해주세요',}
                  )}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>장소</S_Label>
                <RegisterInput
                  errorMessage={errors?.place?.message}
                  isError={errors?.place ? true : false}
                  register={register('place', 
                  {required: '내용을 입력해주세요' }
                  )}
                  style={{ width: '380px' }}
                />
                
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
            

            
            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>

              <S_BasicInfoWrapper>
                <S_Label style={{ color: '' }}>주소</S_Label>
                <RegisterInput
                  errorMessage={errors?.address?.message}
                  isError={errors?.address ? true : false}
                  register={register('address', 
                  { required: '내용을 입력해주세요' }
                  )}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>

              <S_BasicInfoWrapper>
                <S_Label>추천상품</S_Label>
                <S_Select 
                  {...register('recommend', 
                    { required: '선택해주세요' } )
                  } 
                  defaultValue={ isTravelProduct?.recommend ? 'true' : 'false'}>
                  <option value={""} >선택해주세요</option> {/* 기본값을 설정하는 옵션 */}
                  {travelproductRecommend.map((item) => (
                    <option key={item.key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </S_Select>
                {errors.recommend && <S_ErrorMessage>{errors.recommend.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>        
            
                    

            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>

              <S_BasicInfoWrapper>
                <S_Label>가격</S_Label>
           
                <RegisterInput
                  type="number"
                  errorMessage={errors?.price?.message} // 오류 메시지 전달
                  isError={errors?.price ? true : false}
                  defaultValue='0'
                  register={register('price', 
                  { required: '입력해주세요' }
                  )}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>

              <S_BasicInfoWrapper>
                <S_Label>가격할인율</S_Label>
           
                <RegisterInput
                  type="number"
                  errorMessage={errors?.salePercent?.message} // 오류 메시지 전달
                  isError={errors?.salePercent ? true : false}
                  defaultValue='0'
                  register={register('salePercent', 
                  { required: '입력해주세요' }
                  )}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              
            </S_BasicInfoRowWrapper>        

            
            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>

              <S_BasicInfoWrapper>
                <S_Label>활성화 여부</S_Label>

                <S_Select
                  {...register("travelProductStatus",
                    {required: '선택해주세요'}
                  )}
                  defaultValue={watch('travelProductStatus')}
                >
                  <option value={""}>선택해주세요</option>
                  {travelproductActiveStatus.map((item) => (
                    <option key={item.key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </S_Select>
                {errors.travelProductStatus && <S_ErrorMessage>{errors.travelProductStatus.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>


              <S_BasicInfoWrapper>
                <S_Label>환불 기준일</S_Label>
           
                <RegisterInput
                  type="number"
                  errorMessage={errors?.refundDate?.message} // 오류 메시지 전달
                  isError={errors?.refundDate ? true : false}
                  defaultValue=''
                  register={register('refundDate', 
                  { required: '입력해주세요' }
                  )}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>


            </S_BasicInfoRowWrapper>        

            <S_BasicInfoRowFullWrapper style={{ marginTop: '12px' }}>
              <S_BasicInfoWrapper>
                <S_Label style={{ color: '' }}>상세정보</S_Label>
                <ToastEditor 
                  onParentDescription={handlerDescription}
                  initialEditType={'markdown'}
                  previewStyle={'vertical'}
                  placeholder = {'여행상품 상세정보를 작성해주세요!'}
                  initialValue={watch('content')} 
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowFullWrapper>

          </S_BasicInfoContainer>
        </S_TopContainer>


        <S_MidContainer>
          <S_BasicInfoTitle>지도</S_BasicInfoTitle>

          <S_BasicInfoRowFullWrapper style={{ marginTop: "12px" }}>
            <S_BasicInfoWrapper>
              <S_Label style={{ color: "" }}>지도</S_Label>
              <SearchMapWithClickAndMarker setLattLong={setLattLong} />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowFullWrapper>

          <S_BasicInfoRowWrapper style={{ marginTop: "12px" }}>
            <S_BasicInfoWrapper>
              <S_Label style={{ color: "" }}>위도</S_Label>
              <RegisterInput
                isError={errors?.latt ? true : false}
                register={register("latt")}
                placeholder="위도"
                value={watch("latt")}
                // readOnly
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label style={{ color: "" }}>경도</S_Label>
              <RegisterInput
                isError={errors?.long ? true : false}
                register={register("long")}
                placeholder="경도"
                value={watch("long")}
                // readOnly
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
        </S_MidContainer>



        <S_MidContainer>
          <S_BasicInfoTitle>영업불가 날짜 설정</S_BasicInfoTitle>

          <S_BasicInfoWrapper>
            <S_Label>불가 날짜 선택</S_Label>
            <DatePicker
              value={watch(`travelProductDisableDate`).map(d => new DateObject({ date: d.date, format }))}
              onChange={(dates) => {
                const formattedDates = dates.map(date => ({
                  // id: 0,
                  // travelProductId: 0,
                  date: date.format(format)
                }));
                console.log('formattedDates : ', formattedDates);
                setValue(`travelProductDisableDate`, formattedDates);
              }}
              multiple
              sort
              format={format}
              minDate={today} 
              calendarPosition="bottom-center"
              plugins={[<DatePanel key="date-panel" />]}
            />
          </S_BasicInfoWrapper>

          <S_BasicInfoRowWrapper>
            <S_SelectedDates>
              {watch(`travelProductDisableDate`).map((date, index) => (
                <S_DateItemRed key={index}>
                  {date.date}
                </S_DateItemRed>
              ))}
            </S_SelectedDates>
          </S_BasicInfoRowWrapper>
        </S_MidContainer>


        <S_MidContainer>
          <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>
            <S_BasicInfoWrapper>
              <S_Label>환불 규칙</S_Label>
              <textarea
                {...register('refundRule', {
                  // validation을 추가할 수 있음
                  required: '환불 규칙을 입력해주세요.',
                })}
                style={{ width: '600px', height: '500px', padding: '10px' }} // 원하는 크기 및 스타일 설정
                defaultValue={""}
                placeholder="환불 규칙을 입력해주세요"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation(); // 기본 동작인 폼 제출 방지
                  }
                }}
              />
              {errors.refundRule && <S_ErrorMessage>{errors.refundRule.message}</S_ErrorMessage>} {/* 오류 메시지 */}
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
        </S_MidContainer>

        {/* 호텔링 이후 이미지 수정 개발하기로 */}
        {/* <S_BottomContainer>
          <S_ImageTitle>상품 사진 첨부</S_ImageTitle>
          <S_ImageContainer>
            <div>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label>대표 이미지</S_Label>
                  <S_InputContainer
                    isDisabled={true}
                    isError={errors?.uploadFiles ? true : false}
                  >
                    <input
                      type='file'
                      id='uploadFiles'
                      accept='.jpeg,.jpg,.png,.bmp,.gif'
                      multiple
                      {...register('uploadFiles', {
                        onChange: handleFileSet, // 파일 변경시 실행될 함수
                        // required: '필수 입력 항목입니다.',
                      })}
                    />

                    <S_ImageList>
                      {isFiles.length > 0
                        ? isFiles.map((f: File) => <span key={f.name}>{f.name}</span>)
                        : Array.from(watch('uploadFiles') || []).map((f: File) => (
                            <span key={f.name}>{f.name}</span>
                          ))}
                    </S_ImageList>
                  </S_InputContainer>
                  <S_AddImage htmlFor='uploadFiles'>사진 추가</S_AddImage>
                </S_BasicInfoWrapper>

                {isFiles.length > 0 && (
                  <S_BasicInfoRowWrapperImage>
                    <DraggableList
                      key={isFiles.map(f => f.name).join()}
                      items={isFiles.map((file, index) => ({
                        id: index.toString(),
                        fileName: file.name,
                        file,
                      }))}
                      onItemsChange={handleItemsChangeDnd}
                      direction="horizontal"
                    />
                  </S_BasicInfoRowWrapperImage>
                )}


              </S_BasicInfoRowWrapper>

              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label />
                  <S_ImageDesc>
                    *최대 5개 이미지 첨부 가능
                    <br />
                    *가로 길이 x 세로 길이(px) : 860 x 5000(px)
                    <br />
                    *제한 용량 : 20MB
                    <br />
                    *지원 양식 : JPG/JPEG/PNG
                  </S_ImageDesc>
                </S_BasicInfoWrapper>
              </S_BasicInfoRowWrapper>


            </div>
           
          </S_ImageContainer>
        </S_BottomContainer> */}



        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LongButton>{isLoading ? <Spinner /> : '수정하기'}</LongButton>
        </div>
      </form>
    </MainLayout>
  );
}

export default TravelProductRegistration;

const S_RemoveButton = styled.div`
  position: absolute;
  right: -40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const S_ImageDesc = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_ImageTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
`;

const S_BottomContainer = styled.div`
  padding: 24px 0 64px;
`;

const S_FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_AddInfoContainer = styled.div`
  margin: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const S_MidContainer = styled.div`
  padding: 24px 0 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_AddImage = styled.label`
  width: 111px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  margin-left: 23px;
  /* margin-right: 20px; */
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const S_ImageList = styled.div`
  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    width: 346px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const S_InputContainer = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
}>`
  width: 380px;
  min-height: 46px;
  padding: 14px 16px;
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
  margin-top: 10px;
`;

const S_BasicInfoRowWrapperImage = styled.div`
  margin-top: 16px;
  margin-left: 30px;
  display: flex;
  justify-content: flex-start; /* 이미지들을 왼쪽으로 정렬 */
  width: 100%; /* 부모 요소의 너비에 맞춤 */

`;

const S_HalfWidthWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:first-child {
    margin-right: 45px;
  }
`;



const S_BasicInfoRowFullWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
  width: 100%;
`;


const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;


const S_Select = styled.select`
  width: 380px;
  height: 46px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.ho6};
  font-size: 12px;
  margin-top: 5px;
  margin-left: 10px;
`;

const S_SelectedDates = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;  /* DatePicker와 약간의 간격 추가 */
  gap: 10px;  /* 각 날짜 간의 간격 */
`;

const S_DateItem = styled.div`
  padding: 8px 12px;
  background-color: ${(props) => props.theme.colors.hd5};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.ho1};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const S_DateItemRed = styled.div`
  padding: 8px 12px;
  background-color: ${(props) => props.theme.colors.hd5};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.ho7};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const S_Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.hd4}; /* 구분선 색상 */
  margin: 20px 0; /* 위아래 여백 */
`;


const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 200px;
`;