// theme 정의
import { DefaultTheme } from 'styled-components';

// 어두운 테마
export const theme: DefaultTheme = {
  colors: {
    ho1: '#13A872',
    ho2: '#4EBE95',
    ho3: '#C4E9DC',
    ho4: '#277C5D',
    ho5: '#47C21C',
    ho6: '#FC1D1D',
    ho7: '#e82059',

    hd1: '#000000',
    hd2: '#888888',
    hd3: '#B6B6B6',
    hd4: '#F0F0F0',
    hd5: '#FFFFFF',

    success: '#13A872', // 성공 상태 (녹색)
    warning: '#ffc107', // 경고 상태 (노란색)
    danger: '#dc3545',  // 위험 상태 (빨간색)
    primary: '#007bff', // 기본 버튼 (파란색)
  },
};