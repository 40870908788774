import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MainLayout from "components/Layout/MainLayout";
import { I_DisableDateItem, I_HotelDisableDateForm, I_HotelDisableDateList } from "etc/types";
import DisableDateItem from "components/Hotel/DisableDateItem";
import { getDisableList, patchDisableDate } from 'api/hotel';
import useLogout from 'hooks/useLogout';
import { EHotelProductType } from "etc/enum";

function HotelDisableDate() {
  const format = "YYYY-MM-DD";
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const Logout = useLogout();
  
  const hotelForm = useForm<{ dates: I_HotelDisableDateList[] }>({
    mode: "onChange",
    defaultValues: { dates: [] },
  });
  const daycareForm = useForm<{ dates: I_HotelDisableDateList[] }>({
    mode: "onChange",
    defaultValues: { dates: [] },
  });
  const timecareForm = useForm<{ dates: I_HotelDisableDateList[] }>({
    mode: "onChange",
    defaultValues: { dates: [] },
  });

  const [ isHotelList, setHotelList] = useState<I_HotelDisableDateList[]>([]);
  const [ isDaycareList, setDaycareList] = useState<I_HotelDisableDateList[]>([]);
  const [ isTimecareList, setTimecareList] = useState<I_HotelDisableDateList[]>([]);

  const getAsyncList = async (type: EHotelProductType) => {
    const data = await getDisableList('1', type);
    console.log(type, ' data : ', data)

    let filteredResults: I_DisableDateItem[] = [];

    if (data.ok) {
      switch (type) {
        case EHotelProductType.HOTELING:
          filteredResults = data.res.filter(
            (item: { hotelproducttype: EHotelProductType }) =>
              item.hotelproducttype === EHotelProductType.HOTELING
          );
          setHotelList(filteredResults);
          hotelForm.reset({ dates: filteredResults });
          break;
        case EHotelProductType.DAYCARE:
          filteredResults = data.res.filter(
            (item: { hotelproducttype: EHotelProductType }) =>
              item.hotelproducttype === EHotelProductType.DAYCARE
          );
          setDaycareList(filteredResults);
          daycareForm.reset({ dates: filteredResults });
          break;
        case EHotelProductType.TIMECARE:
          setTimecareList(data.res);
          timecareForm.reset({ dates: data.res });
          break;
        default:
          break;
      }
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncList(EHotelProductType.HOTELING);
    getAsyncList(EHotelProductType.DAYCARE);
    getAsyncList(EHotelProductType.TIMECARE);
  }, []);

  const onSubmit = async (data: { dates: I_HotelDisableDateList[] }, type: EHotelProductType) => {
    try {
      console.log('data : ', data, 'type : ', type);
      // dates 배열을 문자열 배열로 변환
      const formattedDates = data.dates.map((item) => item.date);
  
      // API 호출
      const response = await patchDisableDate('1', {
        dateList: formattedDates,
        hotelProductType: type,
      });
  
      if (response.ok) {
        // 성공적으로 처리된 경우
        // navigate('/service/some-path'); // 필요 시 라우트 이동
        alert(`${type} 이 업데이트 됐습니다`);
      } else {
        if (response.res.status === 315) {
          Logout(); // 세션 만료 시 로그아웃 처리
        } else {
          alert('잠시 후 다시 시도해주세요. '+response.res.error.message);
          console.error(response.res.error.message);
        }
      }
    } catch (error) {
      console.error('데이터 저장 실패:', error);
      alert(`${type} 데이터 저장에 실패했습니다.`);
    }
  };

  return (
    <MainLayout>
      <DisableDateItem
        title="호텔링 영업불가 날짜 설정"
        form={hotelForm}
        onSubmit={(data) => onSubmit(data, EHotelProductType.HOTELING)}
        format={format}
        today={today}
      />
      <DisableDateItem
        title="유치원 영업불가 날짜 설정"
        form={daycareForm}
        onSubmit={(data) => onSubmit(data, EHotelProductType.DAYCARE)}
        format={format}
        today={today}
      />
      <DisableDateItem
        title="시간권 영업불가 날짜 설정"
        form={timecareForm}
        onSubmit={(data) => onSubmit(data, EHotelProductType.TIMECARE)}
        format={format}
        today={today}
      />
    </MainLayout>
  );
}

export default HotelDisableDate;
