export enum EEnableStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}
export enum EActiveStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}
export enum ERecommendStatus {
  RECOMMEND = 'RECOMMEND',
  NOT_RECOMMEND = 'NOT_RECOMMEND',
}
export enum EBooleanStatus {
  TRUE = 'true',
  FALSE = 'false',
}
export enum EHotelProductType {
  HOTELING = 'HOTELING',  // 호텔 상품
  DAYCARE = 'DAYCARE',    // 유치원 상품
  TIMECARE = 'TIMECARE',      // 시간권 상품
}
