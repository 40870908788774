import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as Delete } from "assets/image/minus-circle.svg";

import MainLayout from "components/Layout/MainLayout";

import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import { I_HotelDisableDateForm, I_HotelDisableDateList } from 'etc/types';


interface DisableDateCardProps {
  title: string;
  form: ReturnType<typeof useForm<{ dates: I_HotelDisableDateList[] }>>;
  onSubmit: (data: { dates: I_HotelDisableDateList[] }) => void;
  format: string;
  today: Date;
}

const DisableDateItem: React.FC<DisableDateCardProps> = ({ title, form, onSubmit, format, today }) => {
  
  const { watch, setValue, handleSubmit } = form;
  const watchDates = watch("dates") || [];
  // console.log('watchDates : ', watchDates)
  return (
    <S_Card>
      <S_Title>{title}</S_Title>
      <S_Label>불가 날짜 선택</S_Label>
      <DatePicker
        value={watchDates.map((d) => new DateObject({ date: d.date, format }))}
        onChange={(dates) => {
          const formattedDates = dates.map((date) => ({ date: date.format(format) }));
          setValue("dates", formattedDates);
        }}
        multiple
        sort
        format={format}
        minDate={today}
        calendarPosition="bottom-center"
        plugins={[<DatePanel key="date-panel" />]}
      />
      <S_SelectedDates>
        {watchDates.map((item, i) => (
          <S_DateItem key={i}>
            {item.date}
            <DeleteIcon
              onClick={() => {
                const updatedDates = watchDates.filter((_, idx) => idx !== i);
                setValue("dates", updatedDates);
              }}
            />
          </S_DateItem>
        ))}
      </S_SelectedDates>
      <S_ButtonWrapper>
        <S_SubmitButton onClick={handleSubmit(onSubmit)}>저장</S_SubmitButton>
      </S_ButtonWrapper>
    </S_Card>
  );
};

export default DisableDateItem;

const S_Card = styled.div`
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

const S_Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const S_Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
`;

const S_SelectedDates = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
`;

const S_DateItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  border-radius: 8px;
  font-size: 14px;
  position: relative;
`;

const DeleteIcon = styled(Delete)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
`;

const S_ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const S_SubmitButton = styled.button`
  background-color: ${(props) => props.theme.colors.success};
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.ho2};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.hd3};
    cursor: not-allowed;
  }
`;


