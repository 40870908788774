
export const hotelReservationColor = {
  background: { color: '#fff2d7' },
  disableBackground: { 
    hoteling: { color: '#d62c34' },
    daycare: { color: '#dd545b' },
    timecare: { color: '#d36f74' },
    oneday: { color: '#ec9a9e' },
    onedayHoteling: { color: '#f9b9bc' },
  },
  hoteling: { color: '#2c9c4d' },
  daycare: { color: '#566bca' },
  timecare: { color: '#e8975e' },
}
