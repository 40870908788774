import { EHotelProductType } from 'etc/enum';
import { callApi } from './callApi';

// 유저 리스트
export const getReservationList = async (
  hotelId: string,
  startDate: string,
  endDate: string,
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/reservation/list?startDate=${startDate}&endDate=${endDate}`,
    method: 'GET',
  });
  return res;
};

export const getDisableList = async (
  hotelId: string,
  hotelProductType: EHotelProductType,
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/disable-date/list?hotelProductType=${hotelProductType}`,
    method: 'GET',
  });
  return res;
};

export const patchDisableDate = async (
  hotelId: string, // 호텔 ID
  payload: {
    dateList: string[];
    hotelProductType: EHotelProductType; // 호텔 상품 타입
  }
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/disable-date`,
    method: 'PATCH',
    data: payload,
  });
  return res;
};
