import { useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Img } from 'assets/image/img.svg';
import { ReactComponent as Close } from 'assets/image/close.svg';

interface I_ImageInput {
  setState: React.Dispatch<React.SetStateAction<File>>;
  style?: React.CSSProperties;
  id?: number;
}

function ImageInput({ setState, style, id }: I_ImageInput) {
  const [imagePreview, setImagePreview] = useState<string>(''); // 미리보기 변수

  // 사진 삭제
  const onCloseClick = (e: any) => {
    e.stopPropagation();
    setImagePreview('');
    setState(new File([], ''));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    if (setState) setState(file);
  };

  return (
    <>
      {imagePreview ? (
        <S_Container isPreview={imagePreview ? true : false} style={style}>
          <S_PreviewImage src={imagePreview} />
          <S_CloseIcon onClick={(e) => onCloseClick(e)}>
            <Close />
          </S_CloseIcon>
        </S_Container>
      ) : (
        <S_Container
          htmlFor={`file${id}`}
          isPreview={imagePreview ? true : false}
          style={style}
        >
          <S_Img>
            <Img />
          </S_Img>
          <S_Input
            onChange={onChange}
            type='file'
            id={`file${id}`}
            accept='.jpeg,.jpg,.png,.bmp,.gif'
          />
        </S_Container>
      )}
    </>
  );
}

export default ImageInput;

const S_CloseIcon = styled.div`
  display: none;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  right: 12px;
  cursor: pointer;
`;

const S_PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const S_Input = styled.input`
  display: none;
`;

const S_Img = styled.div`
  width: 25.79px;
  height: 25.79px;
`;

const S_Container = styled.label<{ isPreview: boolean }>`
  width: 321px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: ${(props) => (props.isPreview ? 'normal' : 'pointer')};
  &:hover ${S_CloseIcon} {
    display: flex;
  }
`;
