import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { SIDE_BAR_DATA } from 'etc/SidebarData';
import { CATEGORY_DATA } from 'etc/CategoryData';

import { ReactComponent as ArrowUp } from 'assets/image/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/image/arrow-down.svg';
import { ReactComponent as ArrowRight } from 'assets/image/arrow-right.svg';

function Sidebar() {

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const data: { [key: string]: string[] } = CATEGORY_DATA;
  const menuLocationNameSplit = location.split('/');
  const menuLocationNameReplace = location.replace('/', '').endsWith('/')
    ? location.replace('/', '').slice(0, -1)
    : location.replace('/', '');

  const [selectMenu1, setSelectMenu1] = useState<string>();
  const [selectMenu2, setSelectMenu2] = useState<string>();
  const [selectMenu3, setSelectMenu3] = useState<string>();

  const onClickMenu = (title: string, depth: number, path: string) => {

    
    if (path) {
      navigate(path);
      return false;
    } else {
      if (depth == 1) {
        if (selectMenu1 === title) {
          setSelectMenu1('');
          setSelectMenu2('');
        } else {
          setSelectMenu1(title);
        }
      } else if (depth == 2) {
        console.log('depti 2 path : ', path)

        if (selectMenu2 === title) {
          setSelectMenu2('');
        } else {
          setSelectMenu2(title);
        }
      }
    }
  };

  useEffect(() => {
    const isIdParam =
      menuLocationNameSplit[menuLocationNameSplit.length - 1] === ''
        ? Number(menuLocationNameSplit[menuLocationNameSplit.length - 2])
        : Number(menuLocationNameSplit[menuLocationNameSplit.length - 1]);

    // console.log('isIdParam : ', isIdParam)
    if (isIdParam) {
      if (menuLocationNameSplit[menuLocationNameSplit.length - 1] === '') {
        menuLocationNameSplit.pop();
      }
      menuLocationNameSplit.pop();
      menuLocationNameSplit.shift();
      menuLocationNameSplit.push('id');

      if (data[menuLocationNameSplit.join('/')]) {
        const join = data[menuLocationNameSplit.join('/')];

        if (join.length === 4) {
          setSelectMenu1(join[join.length - 4]);
          setSelectMenu2(join[join.length - 3]);
          setSelectMenu3(join[join.length - 2]);
        } else if (join.length === 3) {
          setSelectMenu1(join[menuLocationNameSplit.length - 3]);
          setSelectMenu2(join[menuLocationNameSplit.length - 2]);
          setSelectMenu3(join[menuLocationNameSplit.length - 1]);
        }
      }
    } else {
      if (data[menuLocationNameReplace]) {
        setSelectMenu1(data[menuLocationNameReplace][0]);
        setSelectMenu2(data[menuLocationNameReplace][1]);
        setSelectMenu3(data[menuLocationNameReplace][2]);
      }
    }
  }, []);


  useEffect(() => {
   
    if(selectMenu1){
      // console.log('selectMenu1 : ', selectMenu1);
    }

    if(selectMenu2){
      // console.log('selectMenu2 : ', selectMenu2);
    }

    if(selectMenu3){
      // console.log('selectMenu3 : ', selectMenu3);
    }

  }, [selectMenu1, selectMenu2, selectMenu3]);

  return (
    <S_Container>
      <S_DepthWrap>
        {SIDE_BAR_DATA.map((depth1: any, i: number) => {
          return (
            <S_Depth1 key={i}>
              <S_Title onClick={() => onClickMenu(depth1.title, 1, '')}>
                <S_Icon src={depth1.icon} />
                {depth1.title}
                <S_Arrow>
                  {depth1.title === selectMenu1 ? (
                    <ArrowDown width='16' height='16' />
                  ) : (
                    <ArrowUp width='16' height='16' />
                  )}
                </S_Arrow>
              </S_Title>
              {depth1.title == selectMenu1 && (
                <S_Depth2Wrap>
                  {depth1.submenu.map((depth2: any, i: number) => {
                    return (
                      <S_Depth2 key={i}>
                        <S_SubTitle
                          onClick={() =>
                            onClickMenu(depth2.title, 2, depth2.pathname)
                          }
                          className={
                            (!selectMenu3 && depth2.title === selectMenu2) ||
                            depth2.title === selectMenu2
                              ? 'active'
                              : ''
                          }
                        >
                          {depth2.submenu && depth2.title == selectMenu2 ? (
                            <ArrowDown width='12' height='12' />
                          ) : (
                            <ArrowRight
                              width='12'
                              height='12'
                              color={
                                depth2.title === selectMenu2 ? '#fff' : '#000'
                              }
                            />
                          )}
                          {depth2.title}
                        </S_SubTitle>
                        {depth2.title == selectMenu2 && (
                          <S_Depth3Wrap>
                            {depth2.submenu?.map((depth3: any, i: number) => {
                              return (
                                <S_Depth3
                                  key={i}
                                  onClick={() =>
                                    onClickMenu(
                                      depth2.title,
                                      3,
                                      depth3.pathname
                                    )
                                  }
                                  className={
                                    depth3.title === selectMenu3 ? 'active' : ''
                                  }
                                >
                                  {depth3.title}
                                </S_Depth3>
                              );
                            })}
                          </S_Depth3Wrap>
                        )}
                      </S_Depth2>
                    );
                  })}
                </S_Depth2Wrap>
              )}
            </S_Depth1>
          );
        })}
      </S_DepthWrap>
    </S_Container>
  );
}

export default Sidebar;

const S_Depth3Wrap = styled.div`
  overflow: hidden;
`;

const S_Depth2 = styled.div``;

const S_SubTitle = styled.div`
  padding: 10px 0 10px 92px;
  background-color: ${(props) => props.theme.colors.ho3};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  position: relative;

  &.active {
    background-color: ${(props) => props.theme.colors.ho4};
    color: ${(props) => props.theme.colors.hd5};
  }
  svg {
    position: absolute;
    left: 53px;
  }
`;

const S_Depth3 = styled.div`
  padding: 10px 0 10px 92px;
  background-color: ${(props) => props.theme.colors.ho2};
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;

  &.active {
    background-color: ${(props) => props.theme.colors.ho4};
  }
`;

const S_Depth2Wrap = styled.div`
  overflow: hidden;
`;

const S_Arrow = styled.div`
  position: absolute;
  top: 16px;
  right: 26px;
`;

const S_Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const S_Title = styled.div`
  display: flex;
  align-items: center;
  gap: 31px;
  padding: 16px 32px;
  color: ${(props) => props.theme.colors.hd5};
  font-weight: 700;
  cursor: pointer;
  position: relative;
`;

const S_Depth1 = styled.div``;

const S_DepthWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const S_Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  flex-shrink: 0;
  min-height: calc(100vh - 53px);
  background-color: ${(props) => props.theme.colors.ho1};
`;
